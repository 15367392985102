import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Container,
  Fab,
  Box,
  Typography,
  Grid,
  Button,
  Tooltip,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import PanToolIcon from "@material-ui/icons/PanTool";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CouponsContext from "../../Reducers/Coupons/CouponsContext";

import MaterialTable from "material-table";
/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "11px",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const CouponsSeller = (props) => {
  const classes = useStyles();

  const [cargando, spinnerCargando] = useState(false);

  const CouponsList = useContext(CouponsContext);
  const { coupons, getCoupons, success } = CouponsList;

  const StateCouponsContext = useContext(CouponsContext);
  const { date, saveCurrentCoupon, deleteCoupon } = StateCouponsContext;

  const SelectCoupons = (coupon) => {
    saveCurrentCoupon(coupon);
  };

  //obtener los proveedores carga el componente
  useEffect(() => {
    getCoupons();
    spinnerCargando(true);
  }, [success]);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* separacion */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            marginTop: 85,
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 20,
          }}
        >
          <Typography
            className={classes.typography}
            component='h1'
            variant='h4'
          >
            Cupones De Descuento
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align='right'
          style={{ marginRight: 30, marginTop: -30 }}
        >
          {/* <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            href="/AgregarCupon"
          >
            <Tooltip
              title="Agregar Cupón De Descuento"
              aria-label="Agregar Cupón De Descuento"
            >
              <AddIcon />
            </Tooltip>
          </Fab> */}
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title=''
            columns={[
              { title: "Código", field: "code" },
              {
                title: "Descuento",
                field: "discount",
                render: (rowData) => <p>{rowData.discount} %</p>,
              },
              {
                title: "Tipo",
                field: "type",
                render: (rowData) =>
                  rowData.type == 1 ? (
                    <p>Venta</p>
                  ) : rowData.type == 2 ? (
                    <p>Inscripcion</p>
                  ) : (
                    <p>Ambos</p>
                  ),
              },
              { title: "Fecha De Expiración", field: "expiration" },
              {
                title: "Estatus",
                field: "expiration_date",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {date >= rowData.expiration_date ? (
                        <p
                          style={{
                            color: "white",
                            background: "red",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align='center'
                        >
                          Cupón Expirado
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "white",
                            background: "#43a047",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align='center'
                        >
                          Cupón Válido
                        </p>
                      )}
                    </Fragment>
                  );
                },
              },
            ]}
            data={coupons}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No Hay Descuentos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=''
            columns={[
              { title: "Código", field: "code" },
              {
                title: "Descuento",
                field: "discount",
                render: (rowData) => <p>{rowData.discount} %</p>,
              },
            ]}
            data={coupons}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>
                        Tipo:{" "}
                        {rowData.type == 1
                          ? "Venta"
                          : rowData.type == 2
                          ? "Inscripcion"
                          : "Ambos"}
                      </p>
                      <p>Fecha De Expiración: {rowData.expiration}</p>
                      <p>Estatus:</p>
                      <p>
                        {
                          <Fragment>
                            {date >= rowData.expiration_date ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "red",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align='center'
                              >
                                Cupón Expirado
                              </p>
                            ) : (
                              <p
                                style={{
                                  color: "white",
                                  background: "#43a047",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align='center'
                              >
                                Cupón Válido
                              </p>
                            )}
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No Hay Descuentos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </LayoutDashboard>
  );
};

export default CouponsSeller;
