import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import {
  Button,
  Tooltip,
  Hidden,
  Grid,
  Fab,
  Typography,
  IconButton,
  TextField,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import ModalImportProducts from "./ModalImportProducts";
import ModalUpdateProducts from "./ModalUpdateProducts";
import ProductContext from "../../Reducers/Products/ProductContext";
import UpdateIcon from "@material-ui/icons/Update";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ProductsExportExcel from "../Products/ProductsExportExcel";
import MethodGet from "../../Config/Services";
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const OfficeProductsSubAdmin = (props) => {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);
  const { id } = props; //Se toma el ID de la Sucursal para mostrar sus productos

  // const [operator, setOperator] = useState({});
  const [branch, guardarBranch] = useState({});

  const ProductsList = useContext(ProductContext);
  const { products, getProducts, success, DeleteProduct } = ProductsList;

  //Extraer valores de branch(destructuracion)
  const { name, direction, number_phone, state_id, municipality_id } = branch;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openUpdate, setOpenUpdate] = useState(false);

  const handleOpenUpdate = () => {
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  useEffect(() => {
    getProducts(id);
    spinnerCargando(true);
  }, [success]);

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  const [searchText, setSearchText] = useState('');

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchText.toLowerCase()) ||
    product.tag.toLowerCase().includes(searchText.toLowerCase())
  );

  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* titulo */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 100,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography component="h1" variant="h4">
            {"Productos de la Sucursal " + name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Todos los productos registrados de la sucursal
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            color="primary"
            aria-label="add"
            size="medium"
            href={"/Agregar_Producto/" + id}
          >
            <Tooltip
              title={"Agregar Producto Sucursal " + name}
              aria-label={"Agregar Producto Sucursal " + name}
            >
              <AddIcon size="large" />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
       {/* Pantallas grandes */}
      <Hidden only={["xs", "sm"]}> 
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title={
              <>
                {products && products.length !== 0 ? (
                  <Button
                    variant="outlined"
                    onClick={handleOpenUpdate}
                    startIcon={<UpdateIcon />}
                  >
                    Actualizar
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleOpen}
                    startIcon={<CloudUploadIcon />}
                  >
                    Importar
                  </Button>
                )}
                <ProductsExportExcel id={id} />
              </>
            }
            columns={[
              { title: "Clave", field: "tag" },
              {
                title: "Imagen",
                field: "image",
                render: (rowData) =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      alt="producto"
                    />
                  ) : (
                    <ModalImage
                      small={
                        "https://pisouniversitario.com/template/images/page-404.jpg"
                      }
                      large={
                        "https://pisouniversitario.com/template/images/page-404.jpg"
                      }
                      alt="producto"
                    />
                  ),
              },
              { title: "Nombre", field: "name" },
              { title: "Descripción", field: "description" },
              /* { title: "Ubicacion", field: "ubication" }, */
              { title: "Categoria", field: "categoria" },
              { title: "Subategoria", field: "subcategoria" },
              {
                title: "Precio Público",
                field: "price",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price),
              },
              {
                title: "Precio Costo",
                field: "price_purchase",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price_purchase),
              },
              {
                title: "Precio M.",
                field: "price_retail",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price_retail),
              },
              {
                title: "Precio 1",
                field: "price1",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price1),
              },
              {
                title: "Precio 2",
                field: "price2",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price2),
              },
              { title: "Cantidad", field: "quantity" },
            ]}
            data={products}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "delete",
                tooltip: "Eliminar Producto",
              }),
            ]}
            components={{
              Action: (props) => (
                <Fragment>
                  <Link
                    to={`/Editar_Producto/${props.data.id}`}
                    style={{ color: "#3f51b5" }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "none",
                        background: "white",
                      }}
                      size="small"
                    >
                      <Tooltip
                        title="Editar Producto"
                        aria-label="Editar Producto"
                      >
                        <EditIcon style={{ color: "blue" }} />
                      </Tooltip>
                    </Button>
                  </Link>
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      {/* Pantallas pequeñas */}
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <TextField
            label="Buscar nombre o clave"
            variant="outlined"
            fullWidth
            style={{ marginTop: 10 }}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <MaterialTable
            title={
              <>
                {products && products.length !== 0 ? (
                  <Button
                    variant="outlined"
                    onClick={handleOpenUpdate}
                    startIcon={<UpdateIcon />}
                  >
                    Actualizar
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleOpen}
                    startIcon={<CloudUploadIcon />}
                  >
                    Importar
                  </Button>
                )}
                <ProductsExportExcel id={id} />
              </>
            }
            columns={[
              { title: "Clave", field: "tag" },
              {
                title: "Imagen",
                field: "image",
                render: (rowData) =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      alt="producto"
                    />
                  ) : (
                    <ModalImage
                      small={
                        "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png"
                      }
                      large={
                        "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png"
                      }
                      alt="producto"
                    />
                  ),
              },
            ]}
            data={products}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Nombre: {rowData.name}</p>
                      <p>Descripcion: {rowData.description}</p>
                      {/* <p>Ubicacion: {rowData.ubication}</p> */}
                      <p>Categoria: {rowData.categoria}</p>
                      <p>Subcategoria: {rowData.subcategoria}</p>
                      <p>Precio Publico: ${rowData.price}</p>
                      <p>Precio Costo: ${rowData.price_purchase}</p>
                      <p>Precio Menudeo: ${rowData.price_retail}</p>
                      <p>Precio 1: ${rowData.price1}</p>
                      <p>Precio 2: ${rowData.price2}</p>
                      <p>Cantidad: {rowData.quantity}</p>
                      <p>Opciones: </p>
                      <p>
                        {
                          <Fragment>
                            <Link
                              to={`/Editar_Producto/${rowData.id}`}
                              style={{ color: "#3f51b5" }}
                            >
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 100,
                                }}
                                size="small"
                              >
                                <Tooltip
                                  title="Editar Producto"
                                  aria-label="Editar Producto"
                                >
                                  <EditIcon style={{ color: "blue" }} />
                                </Tooltip>
                              </IconButton>
                            </Link>
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No Hay Productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <ModalImportProducts
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        spinnerCargando={spinnerCargando}
        id={id}
      />
      <ModalUpdateProducts
        open={openUpdate}
        handleOpen={handleOpenUpdate}
        handleClose={handleCloseUpdate}
        spinnerCargando={spinnerCargando}
        id={id}
      />
    </LayoutDashboard>
  );
};

export default OfficeProductsSubAdmin;
